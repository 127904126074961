import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { useAuth } from "gatsby-theme-firebase"

const Title = styled.h1`
  color: var(--white);
  font-size: 2.5em;
  font-weight: 600;
  text-shadow: 1.5px 1.5px black;
`

const Subtitle = styled.p`
  color: var(--white);
  font-size: 1.2em;
  text-shadow: 1px 1px black;
`

const EarthChangers = styled.div`
  float: right;
  display: flex;
  padding-top: 200px;
  color: var(--white);
  flex-direction: column;
  justify-content: center;
`

const Homepage = () => {
  const { isLoggedIn } = useAuth()
  return (
    <div style={{ marginBottom: "3rem", alignItems: "center" }}>
      {/* <EarthChangers>
        <div style={{ borderRight: "5px white solid", paddingRight: "10px" }}>
          <h2 className="text-4xl font-bold">726</h2>
          <span className="uppercase text-xs">Advocates</span>
        </div>
      </EarthChangers> */}
      <div
        style={{
          maxWidth: "40em",
          backgroundColor: "#00000055",
          padding: "1.5rem",
          borderRadius: "2px",
        }}
      >
        <div className="top">
          <Title>We'll Help</Title>
          <Title>You Change Earth.</Title>
        </div>

        <br></br>

        <Subtitle>
          We have ten years to avoid locking in catastrophic changes to our
          climate. There is so much we can do. Don’t be an observer to disaster
          - be a participant in solving our planet’s greatest challenge.
        </Subtitle>
        <br />
        <Subtitle>
          Take back hope. Start changing Earth with the actions tailored to you.
        </Subtitle>

        <br />
        <div
          style={{
            display: "flex",
            flexFlow: "row wrap",
            alignItems: "center",
          }}
        >
          {isLoggedIn ? (
            <Link to="/dashboard" style={{ textDecoration: "none" }}>
              <div
                className="button homepageButton"
                style={{
                  width: "12rem",
                  marginTop: "1rem",
                  marginRight: "2rem",
                  marginBottom: "1rem",
                  color: "white",
                  textAlign: "center",
                  textDecoration: "none",
                }}
              >
                <p
                  style={{
                    fontSize: "0.9rem",
                    fontWeight: 700,
                    textDecoration: "none",
                  }}
                >
                  Return to Dashboard
                </p>
              </div>
            </Link>
          ) : (
            <Link to="/mainform" style={{ textDecoration: "none" }}>
              <div
                className="button homepageButton"
                style={{
                  width: "12rem",
                  marginTop: "1rem",
                  marginRight: "2rem",
                  marginBottom: "1rem",
                  color: "white",
                  textAlign: "center",
                  textDecoration: "none",
                }}
              >
                <p
                  style={{
                    fontSize: "0.9rem",
                    fontWeight: 700,
                    textDecoration: "none",
                  }}
                >
                  GET STARTED
                </p>
              </div>
            </Link>
          )}
          <div>
            <p>
              <a
                style={{
                  color: "white",
                  textDecoration: "none",
                  borderBottom: "2px solid white",
                  paddingBottom: "0.5rem",
                  lineHeight: "2",
                }}
                href="https://350.org/science/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <b>Why does climate change matter?</b>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Homepage

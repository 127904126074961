import React, { useEffect } from "react"

import SEO from "../components/seo"
import HomepageLayout from "../layouts/homepageLayout"
import Homepage from "../sections/homepage"

import queryString from "query-string"

const IndexPage = props => {
  useEffect(() => {
    const query = queryString.parse(window.location.search)
    if (query && query.ref_code) {
      sessionStorage.setItem("ref_code", query.ref_code)
    }
  }, [])
  return (
    <HomepageLayout>
      <SEO title="Home" />
      <Homepage />
    </HomepageLayout>
  )
}

export default IndexPage

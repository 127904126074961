import React, { useRef } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../components/header"
import "./layout.css"
import Reveal from "react-reveal/Reveal"
import Footer from "../components/footer"
import Img from "gatsby-image"
import { useAuth } from "gatsby-theme-firebase"
import styled from "styled-components"
import { INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Globe from "../images/globe.png"
import Question from "../images/question.png"
import Checkmark from "../images/checkmark.png"

const website_url = "https://youchangeearth.org"
const options = {
  renderText: text =>
    text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  renderNode: {
    [INLINES.HYPERLINK]: node => {
      return (
        <a
          href={node.data.uri}
          target={`${
            node.data.uri.startsWith(website_url) ? "_self" : "_blank"
          }`}
          rel={`${
            node.data.uri.startsWith(website_url) ? "" : "noopener noreferrer"
          }`}
          style={{
            textDecoration: "none",
            color: "var(--green)",
          }}
        >
          {node.content[0].value}
        </a>
      )
    },
  },
}

const ContentContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 4rem 1rem;
  row-gap: 3rem;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
  padding-bottom: 1rem;
  @media (min-width: 768px) {
    display: grid;
    padding: 4rem 2rem;
    padding-bottom: 1rem;
    row-gap: 5rem;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
  }
`

const FlipOrderImg = styled(Img)`
  order: 1;
  @media (min-width: 768px) {
    order: initial;
  }
`

const FlipOrderDiv = styled.div`
  order: 2;
  @media (min-width: 768px) {
    order: initial;
  }
`

const StepsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.2em;
  margin-top: 2em;
  flex-flow: row wrap;
  @media (min-width: 1440px) {
    gap: 3em;
  }
`

const StepsBox = styled.div`
  border-radius: 5px;
  text-align: center;
  padding: 2em;
  width: 20em;
  margin-bottom: 1em;
  margin: 0.5rem;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
`

const HomepageLayout = ({ children }) => {
  const { isLoggedIn } = useAuth()
  const top = useRef(null)
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "homepage-background-v3.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
      allContentfulHomePageItems(sort: { fields: tag }) {
        nodes {
          id
          title
          image {
            fluid {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          tag
          descriptionNew {
            json
          }
        }
      }
    }
  `)
  return (
    <div ref={top}>
      <BackgroundImage
        Tag="section"
        fluid={data.placeholderImage.childImageSharp.fluid}
        backgroundColor={`#040e18`}
      >
        <Header
          siteTitle={data.site.siteMetadata.title}
          textColor="white"
          logoColor="white"
        />
        <div
          style={{
            display: "flex",
            minHeight: "100vh",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Reveal>
            <main
              style={{
                margin: `0 auto`,
                maxWidth: 1366,
                padding: `0 1.0875rem 0`,
                minWidth: "70%",
              }}
            >
              {children}
            </main>
          </Reveal>
        </div>
      </BackgroundImage>
      <div className="flex flex-col justify-center sm:mx-2 md:mx-8 my-4">
        <div className="flex justify-center flex-col">
          <h2 className="text-3xl font-semibold self-center mt-4">
            How it works
          </h2>
          <StepsContainer>
            <StepsBox>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  backgroundColor: "#e9eef9",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "2rem",
                }}
              >
                <img
                  src={Question}
                  alt="Question"
                  style={{
                    height: "50px",
                  }}
                />
              </div>
              <br />
              <h4 style={{ fontSize: "1.4rem" }}>Quick questions</h4>
              <p style={{ marginTop: "1rem" }}>
                Answer a few quick questions and select the meaningful climate
                projects that suit your interests.
              </p>
            </StepsBox>
            <StepsBox>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  backgroundColor: "#e9eef9",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "2rem",
                }}
              >
                <img
                  src={Checkmark}
                  alt="Checkmark"
                  style={{ height: "61px", marginTop: "10px" }}
                />
              </div>
              <br />
              <h4 style={{ fontSize: "1.4rem" }}>Complete your actions</h4>
              <p style={{ marginTop: "1rem" }}>
                Complete the steps on your actions and see your progress on your
                dashboard!
              </p>
            </StepsBox>
            <StepsBox>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  backgroundColor: "#e9eef9",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "2rem",
                }}
              >
                <img src={Globe} alt="globe" style={{ height: "50px" }} />
              </div>
              <br />
              <h4 style={{ fontSize: "1.4rem" }}>Join communities</h4>
              <p style={{ marginTop: "1em" }}>
                You’ve changed Earth. We then show you great communities and
                ways use your voice over our critical next ten years.
              </p>
            </StepsBox>
          </StepsContainer>
          {/* <div className="flex flex-row justify-around flex-wrap ">
            {data.allContentfulHomePageItems.nodes.slice(0, 3).map(item => {
              return (
                <div style={{ flex: "0 1 300px", marginBottom: "1rem" }}>
                  <Img
                    fluid={item.image.fluid}
                    style={{ borderRadius: "1em" }}
                  />
                  <h3 className="text-gray-800 text-lg mt-4 mb-3">
                    {item.title}
                  </h3>
                  <p className="text-gray-600">
                    {documentToReactComponents(
                      item.descriptionNew.json,
                      options
                    )}
                  </p>
                </div>
              )
            })}
          </div> */}
          <div className="mx-auto  pt-8">
            <Link
              to="/guides"
              className="text-green-600 font-bold border-green-600 border-b-2 no-underline pb-1"
            >
              View all climate projects here
            </Link>
          </div>
        </div>
        <ContentContainer>
          {data.allContentfulHomePageItems.nodes.slice(3, 4).map(item => {
            return (
              <>
                <Img
                  style={{
                    width: "75%",
                  }}
                  className="float-left w-full inline mt-4"
                  fluid={item.image.fluid}
                />
                <div style={{ marginBottom: "1rem", padding: "2rem" }}>
                  <h3
                    className="text-black-800 text-3xl mb-8"
                    style={{ fontWeight: "500" }}
                  >
                    {item.title}
                  </h3>
                  <p className="text-black-600 text-xl">
                    {documentToReactComponents(
                      item.descriptionNew.json,
                      options
                    )}
                  </p>
                  {isLoggedIn ? (
                    <Link to="/dashboard" style={{ textDecoration: "none" }}>
                      <div
                        className="button homepageButton"
                        style={{
                          width: "15rem",
                          marginTop: "1rem",
                          color: "white",
                          textAlign: "center",
                          textDecoration: "none",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "0.9rem",
                            fontWeight: 700,
                            textDecoration: "none",
                          }}
                        >
                          Return to Dashboard
                        </p>
                      </div>
                    </Link>
                  ) : (
                    <Link to="/mainform" style={{ textDecoration: "none" }}>
                      <div
                        className="button homepageButton"
                        style={{
                          width: "15rem",
                          marginTop: "1rem",
                          color: "white",
                          textAlign: "center",
                          textDecoration: "none",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "0.9rem",
                            fontWeight: 700,
                            textDecoration: "none",
                          }}
                        >
                          GET STARTED
                        </p>
                      </div>
                    </Link>
                  )}
                </div>
              </>
            )
          })}

          {data.allContentfulHomePageItems.nodes.slice(4, 5).map(item => {
            return (
              <>
                <FlipOrderDiv style={{ marginBottom: "1rem", padding: "2rem" }}>
                  <h3
                    className="text-black-800 text-3xl mb-8"
                    style={{ fontWeight: "500" }}
                  >
                    {item.title}
                  </h3>
                  <p className="text-black-600 text-xl">
                    {documentToReactComponents(
                      item.descriptionNew.json,
                      options
                    )}
                  </p>
                </FlipOrderDiv>
                <FlipOrderImg
                  style={{
                    width: "75%",
                  }}
                  className="float-right w-full inline mt-4"
                  fluid={item.image.fluid}
                />
              </>
            )
          })}

          {data.allContentfulHomePageItems.nodes.slice(5, 6).map(item => {
            return (
              <>
                <Img
                  style={{
                    width: "75%",
                  }}
                  className="float-left w-full inline mt-4"
                  fluid={item.image.fluid}
                />
                <div style={{ marginBottom: "1rem", padding: "2rem" }}>
                  <h3
                    className="text-black-800 text-3xl mb-8"
                    style={{ fontWeight: "500" }}
                  >
                    {item.title}
                  </h3>
                  <p className="text-black-600 text-xl">
                    {documentToReactComponents(
                      item.descriptionNew.json,
                      options
                    )}
                  </p>
                </div>
              </>
            )
          })}
        </ContentContainer>
      </div>
      <div
        style={{ backgroundColor: "#05301c" }}
        className="py-24 px-4 text-center"
      >
        <h4 className="text-white mb-16 text-3xl">
          Join us and get started on your climate journey today
        </h4>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {isLoggedIn ? (
            <Link to="/dashboard" style={{ textDecoration: "none" }}>
              <div
                className="button homepageButton"
                style={{
                  width: "12rem",
                  color: "white",
                  textAlign: "center",
                  textDecoration: "none",
                }}
              >
                <p
                  style={{
                    fontSize: "0.9rem",
                    fontWeight: 700,
                    textDecoration: "none",
                  }}
                >
                  Return to Dashboard
                </p>
              </div>
            </Link>
          ) : (
            <Link to="/mainform" style={{ textDecoration: "none" }}>
              <div
                className="button homepageButton"
                style={{
                  width: "12rem",
                  color: "white",
                  textAlign: "center",
                  textDecoration: "none",
                }}
              >
                <p
                  style={{
                    fontSize: "0.9rem",
                    fontWeight: 700,
                    textDecoration: "none",
                  }}
                >
                  GET STARTED
                </p>
              </div>
            </Link>
          )}
        </div>
        <button
          style={{
            position: "absolute",
            right: "20px",
            outline: "none",
          }}
          onClick={() => top.current.scrollIntoView({ behavior: "smooth" })}
        >
          <svg
            width="63"
            height="63"
            viewBox="0 0 63 63"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="31.5" cy="31.5" r="31.5" fill="#8A8686" />
            <path
              d="M29.5268 24.868L29.5268 24.8681C29.7687 24.6535 30.0751 24.5257 30.3976 24.5048C30.5914 24.4903 30.7862 24.5147 30.9705 24.5768C31.156 24.6393 31.3269 24.7385 31.473 24.8686L31.4751 24.8705L31.4751 24.8705L41.0144 33.4558C41.0146 33.456 41.0148 33.4562 41.015 33.4563C41.1576 33.5842 41.2737 33.7389 41.3566 33.9116C41.4396 34.0845 41.4876 34.2722 41.4979 34.4637C41.5082 34.6553 41.4806 34.847 41.4167 35.0278C41.3528 35.2087 41.2538 35.3752 41.1255 35.5177C40.9971 35.6602 40.8419 35.7761 40.6687 35.8585C40.4954 35.941 40.3077 35.9884 40.1161 35.9981C39.9245 36.0079 39.7329 35.9797 39.5522 35.9152C39.3718 35.8509 39.2059 35.7516 39.0639 35.6231C39.0637 35.6229 39.0635 35.6227 39.0632 35.6225L30.4996 27.9124L21.936 35.6225C21.9358 35.6227 21.9355 35.6229 21.9353 35.6232C21.7933 35.7516 21.6274 35.8509 21.447 35.9152C21.2663 35.9797 21.0747 36.0079 20.8832 35.9981C20.6916 35.9884 20.5038 35.9409 20.3306 35.8585C20.1574 35.7761 20.0022 35.6603 19.8738 35.5177L29.5268 24.868ZM29.5268 24.868L29.5242 24.8705M29.5268 24.868L29.5242 24.8705M29.5242 24.8705L19.9849 33.4558M29.5242 24.8705L19.9849 33.4558M19.9849 33.4558C19.9846 33.456 19.9844 33.4562 19.9842 33.4564M19.9849 33.4558L19.9842 33.4564M19.9842 33.4564C19.8416 33.5842 19.7255 33.7389 19.6427 33.9116C19.5597 34.0845 19.5117 34.2721 19.5014 34.4637C19.4911 34.6552 19.5186 34.8469 19.5825 35.0278C19.6465 35.2087 19.7454 35.3752 19.8738 35.5177L19.9842 33.4564Z"
              fill="white"
              stroke="white"
            />
          </svg>
        </button>
      </div>
      <Footer />
    </div>
  )
}

export default HomepageLayout
